define("qrpay-frontend/mirage/routes/v2/merchants", ["exports", "ember-cli-mirage/response"], function (_exports, _response) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /**
   * @param {Object} server - mirage server
   */
  function _default(server) {
    server.namespace = '/api/v2';
    server.post('/merchants/:code/session/:sessionId/card-details', function () {
      return {
        last4Digits: "1234",
        type: "VS"
      };
    });
    server.post('/merchants/:code/checks/:id/session/:sessionId/payment', function () {
      return new _response.default(200);
    });
  }
});