define("qrpay-frontend/templates/components/footer-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cTA6wbB/",
    "block": "{\"symbols\":[\"@isDisplayed\"],\"statements\":[[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"footer-banner\"],[12],[2,\"\\n\\t\\t\"],[10,\"small\"],[12],[2,\"Powered By\"],[13],[2,\"  \"],[10,\"img\"],[14,\"src\",\"/img/shift-4-logo.png\"],[14,\"srcset\",\"/img/shift-4-logo@2x.png 2x, /img/shift-4-logo@3x.png 3x\"],[14,\"alt\",\"Shift 4\"],[12],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "qrpay-frontend/templates/components/footer-banner.hbs"
    }
  });

  _exports.default = _default;
});