define("qrpay-frontend/templates/components/check/loyalty-member", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sHNkgp2h",
    "block": "{\"symbols\":[\"@loyaltyMember\",\"@clearLoyaltyMember\"],\"statements\":[[6,[37,2],[[32,1,[\"guid\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"m-2 p-1 loyalty-member\"],[12],[2,\"\\n\\t\\t\"],[10,\"p\"],[14,0,\"p-points\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"span\"],[14,0,\"balance\"],[12],[2,\"Current balance: \"],[1,[32,1,[\"currentBalance\"]]],[13],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[11,\"button\"],[24,0,\"btn\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,2]],null]],null],[12],[2,\"\\n\\t\\t\\t\\t\\tX\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"if\"]}",
    "meta": {
      "moduleName": "qrpay-frontend/templates/components/check/loyalty-member.hbs"
    }
  });

  _exports.default = _default;
});