define("qrpay-frontend/errors/get-error", ["exports", "qrpay-frontend/errors/bad-payment", "qrpay-frontend/errors/bad-check", "qrpay-frontend/errors/pos-offline", "qrpay-frontend/errors/partial-disallowed", "qrpay-frontend/errors/unexpected-payment", "qrpay-frontend/errors/check-not-found", "qrpay-frontend/errors/store-closed", "qrpay-frontend/errors/session-not-found"], function (_exports, _badPayment, _badCheck, _posOffline, _partialDisallowed, _unexpectedPayment, _checkNotFound, _storeClosed, _sessionNotFound) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _ERROR_CLASSES, _BUTTONS;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var ERROR_CLASSES = (_ERROR_CLASSES = {}, _defineProperty(_ERROR_CLASSES, _sessionNotFound.default.TYPE, _sessionNotFound.default), _defineProperty(_ERROR_CLASSES, _badPayment.default.TYPE, _badPayment.default), _defineProperty(_ERROR_CLASSES, _badCheck.default.TYPE, _badCheck.default), _defineProperty(_ERROR_CLASSES, _posOffline.default.TYPE, _posOffline.default), _defineProperty(_ERROR_CLASSES, _partialDisallowed.default.TYPE, _partialDisallowed.default), _defineProperty(_ERROR_CLASSES, _checkNotFound.default.TYPE, _checkNotFound.default), _defineProperty(_ERROR_CLASSES, _storeClosed.default.TYPE, _storeClosed.default), _ERROR_CLASSES);
  var BUTTONS = (_BUTTONS = {}, _defineProperty(_BUTTONS, _badPayment.default.TYPE, 'Try another Payment method'), _defineProperty(_BUTTONS, _unexpectedPayment.default.TYPE, 'Retry Payment'), _BUTTONS);

  var getError = function getError(errorType, payRoute) {
    var errorClass = ERROR_CLASSES[errorType] || _unexpectedPayment.default;
    return {
      type: errorClass.TYPE,
      title: errorClass.TITLE,
      description: errorClass.DESCRIPTION,
      button: BUTTONS[errorType],
      route: payRoute && BUTTONS[errorType] ? payRoute : undefined
    };
  };

  var _default = getError;
  _exports.default = _default;
});