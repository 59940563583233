define("qrpay-frontend/templates/merchant/check-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wrklFnSw",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"vh-100 d-flex flex-column justify-content-center align-items-center\"],[12],[2,\"\\n\\t\"],[8,\"spinner\",[],[[],[]],null],[2,\"\\n\\t\"],[8,\"alert/title\",[],[[\"@title\"],[\"Loading...\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "qrpay-frontend/templates/merchant/check-loading.hbs"
    }
  });

  _exports.default = _default;
});