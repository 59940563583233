define("qrpay-frontend/mirage/scenarios/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /**
   * @param {Object} server - mirage server
   */
  function _default(server) {
    server.create('merchant', {
      code: 'QQQQQQ',
      isDineInEnabled: false,
      isQRPayEnabled: true
    });
    server.create('merchant', {
      code: 'DDDDDD',
      isDineInEnabled: true,
      isQRPayEnabled: false
    });
    server.create('merchant', {
      code: 'MMMMMM',
      isDineInEnabled: true,
      isQRPayEnabled: true
    });
    server.create('merchant', {
      code: 'NNNNNN',
      isDineInEnabled: false,
      isQRPayEnabled: false
    });
    server.create('merchant', {
      code: 'CCCCCC',
      isDineInEnabled: false,
      isQRPayEnabled: true,
      isLoyaltyEnabled: false
    });
    server.create('check', {
      checkNumber: '1234567',
      id: 'check-1',
      total: 1000,
      amountDue: 1000
    });
    server.create('card', {
      code: 'QQQQQQ',
      sessionId: '1234',
      id: 'check-1'
    });
    server.create('check', {
      checkNumber: '1234567',
      id: 'check-2',
      total: 2000,
      amountDue: 2000
    });
    server.create('check', {
      checkNumber: '1234567',
      id: 'check-with-loyalty-1',
      total: 3000,
      amountDue: 3000,
      loyaltyMemberGuid: 'loyalty-member-guid-1',
      discounts: [{
        id: '1234',
        name: 'Test Discount #1',
        type: 'LOYALTY',
        discountedAmount: 10
      }, {
        id: '2345',
        name: 'Test Discount #2',
        type: 'LOYALTY',
        discountedAmount: 11
      }, {
        id: '3456',
        name: 'Test Discount #3',
        type: 'SOMETHING_ELSE',
        discountedAmount: 12
      }]
    });
    server.create('check', {
      checkNumber: '1234567',
      id: 'check-with-loyalty-dscnt-1',
      total: 4000,
      amountDue: 40000,
      loyaltyMemberGuid: 'loyalty-member-guid-2',
      discount: {
        id: '1234'
      },
      discounts: [{
        id: '1234',
        name: 'Test Discount #1',
        type: 'LOYALTY',
        discountedAmount: 10
      }, {
        id: '2345',
        name: 'Test Discount #2',
        type: 'LOYALTY',
        discountedAmount: 11
      }, {
        id: '3456',
        name: 'Test Discount #3',
        type: 'SOMETHING_ELSE',
        discountedAmount: 12
      }]
    });
    server.create('device');
    server.create('authorize');
  }
});