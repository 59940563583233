define("qrpay-frontend/templates/merchant/choose", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lMP/xPwE",
    "block": "{\"symbols\":[],\"statements\":[[8,\"logo\",[],[[\"@merchant\",\"@back\"],[[32,0,[\"model\",\"merchant\"]],[32,0,[\"back\"]]]],null],[2,\"\\n\\n\"],[10,\"h6\"],[14,0,\"text-center mt-3 mb-3 pt-2\"],[12],[2,\"Select Service\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"mb-4 p-1\"],[12],[2,\"\\n\\t\"],[8,\"link-to\",[[24,0,\"btn btn-block btn-primary\"]],[[\"@route\",\"@model\"],[\"merchant.search\",[32,0,[\"model\",\"merchant\",\"code\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\tPay Now\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[11,\"button\"],[24,0,\"mt-3 btn btn-block btn-outline-primary\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"redirectToSTO\"]]],null]],null],[12],[2,\"\\n\\t\\tOrder Now - Dine In\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "qrpay-frontend/templates/merchant/choose.hbs"
    }
  });

  _exports.default = _default;
});