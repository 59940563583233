define("qrpay-frontend/mirage/config", ["exports", "qrpay-frontend/mirage/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /**
   * Mirage config
   */
  function _default() {
    this.namespace = '/api/v1';
    this.timing = 500;

    _routes.default.merchants(this);

    _routes.default.merchantsv2(this);

    _routes.default.devices(this);
  }
});