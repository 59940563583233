define("qrpay-frontend/templates/components/check/email-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eshu7d0o",
    "block": "{\"symbols\":[\"@email\",\"@setEmail\"],\"statements\":[[10,\"div\"],[14,0,\"m-2 p-1\"],[12],[2,\"\\n\\t\"],[10,\"label\"],[14,\"for\",\"email\"],[14,0,\"mb-2\"],[12],[2,\"\\n\\t\\tReceipt will be sent to your email\\n\\t\"],[13],[2,\"\\n\\t\"],[8,\"input\",[[24,\"placeholder\",\"Your Email *\"],[24,0,\"form-control\"],[24,\"autocomplete\",\"email\"],[24,1,\"email\"],[4,[38,1],[\"input\",[30,[36,0],[[32,2]],null]],null]],[[\"@value\"],[[32,1]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "qrpay-frontend/templates/components/check/email-input.hbs"
    }
  });

  _exports.default = _default;
});