define("qrpay-frontend/templates/components/payment/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jJY5Axki",
    "block": "{\"symbols\":[\"@back\",\"@isLoading\"],\"statements\":[[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"d-flex align-items-center mr-2 mt-2 mb-2 p-1 pl-0\"],[12],[2,\"\\n\\t\\t\"],[11,\"button\"],[24,1,\"btn-back\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,1]],null]],null],[12],[13],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"flex-fill text-center pr-3 title\"],[12],[2,\"Payment\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"unless\"]}",
    "meta": {
      "moduleName": "qrpay-frontend/templates/components/payment/header.hbs"
    }
  });

  _exports.default = _default;
});