define("qrpay-frontend/templates/components/check/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+nMXCYux",
    "block": "{\"symbols\":[\"@country\",\"@check\"],\"statements\":[[10,\"div\"],[14,0,\"d-flex m-1\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"p-2 flex-fill\"],[12],[2,\"\\n\\t\\t\"],[10,\"b\"],[12],[2,\"Check \"],[8,\"check/check-number\",[],[[\"@check\"],[[32,2]]],null],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[6,[37,1],[[32,2,[\"openedAt\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"p-2 flex-fill text-right\"],[12],[2,\"\\n\\t\\t\"],[1,[30,[36,0],[[32,2,[\"openedAt\"]],[32,1]],null]],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[10,\"hr\"],[14,0,\"m-0\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"date-format\",\"if\"]}",
    "meta": {
      "moduleName": "qrpay-frontend/templates/components/check/header.hbs"
    }
  });

  _exports.default = _default;
});