define("qrpay-frontend/templates/components/alert/again", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9qwsrz/y",
    "block": "{\"symbols\":[],\"statements\":[[10,\"img\"],[14,\"src\",\"/img/again.png\"],[14,\"alt\",\"\"],[14,\"role\",\"presentation\"],[14,0,\"mb-3 pb-1\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "qrpay-frontend/templates/components/alert/again.hbs"
    }
  });

  _exports.default = _default;
});