define("qrpay-frontend/templates/components/spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nnikRZRf",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"lds-spinner mb-3 pb-1\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[12],[13],[2,\"\\n\\t\"],[10,\"div\"],[12],[13],[2,\"\\n\\t\"],[10,\"div\"],[12],[13],[2,\"\\n\\t\"],[10,\"div\"],[12],[13],[2,\"\\n\\t\"],[10,\"div\"],[12],[13],[2,\"\\n\\t\"],[10,\"div\"],[12],[13],[2,\"\\n\\t\"],[10,\"div\"],[12],[13],[2,\"\\n\\t\"],[10,\"div\"],[12],[13],[2,\"\\n\\t\"],[10,\"div\"],[12],[13],[2,\"\\n\\t\"],[10,\"div\"],[12],[13],[2,\"\\n\\t\"],[10,\"div\"],[12],[13],[2,\"\\n\\t\"],[10,\"div\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "qrpay-frontend/templates/components/spinner.hbs"
    }
  });

  _exports.default = _default;
});