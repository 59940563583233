define("qrpay-frontend/templates/components/check/pay-now-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8w9dm6SQ",
    "block": "{\"symbols\":[\"@isSubmitDisabled\",\"@payNow\",\"@card\"],\"statements\":[[10,\"div\"],[14,0,\"d-flex m-1 p-2 mb-2 pay-now-button\"],[12],[2,\"\\n\\t\"],[11,\"button\"],[24,0,\"btn btn-success btn-block\"],[16,\"disabled\",[32,1]],[24,4,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,2]],null]],null],[12],[2,\"\\n\\t\\tPay with   \"],[10,\"span\"],[15,0,[31,[[32,0,[\"cardClass\"]]]]],[12],[13],[2,\" ending in \"],[1,[32,3,[\"last4Digits\"]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "qrpay-frontend/templates/components/check/pay-now-button.hbs"
    }
  });

  _exports.default = _default;
});