define("qrpay-frontend/templates/device", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e5TkpZPz",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"unloader\",[],[[\"@code\"],[[32,1,[\"code\"]]]],null],[2,\"\\n\"],[8,\"check/header\",[],[[\"@check\",\"@country\"],[[32,1,[\"device\",\"check\"]],\"US\"]],null],[2,\"\\n\"],[8,\"check/items\",[],[[\"@items\",\"@tips\",\"@currency\",\"@country\"],[[32,1,[\"device\",\"check\",\"items\"]],[32,1,[\"device\",\"check\",\"tips\"]],\"USD\",\"US\"]],null],[2,\"\\n\"],[8,\"check/tips\",[],[[\"@currency\",\"@country\",\"@tips\",\"@total\",\"@amount\",\"@active\",\"@setTips\",\"@isNoTipsChosen\"],[\"USD\",\"US\",[32,1,[\"device\",\"tips\"]],[32,1,[\"device\",\"check\",\"total\"]],[32,0,[\"tips\"]],[32,0,[\"active\"]],[32,0,[\"setTips\"]],[32,0,[\"isNoTipsChosen\"]]]],null],[2,\"\\n\"],[8,\"check/totals\",[],[[\"@check\",\"@tip\",\"@tipsTitle\",\"@tips\",\"@currency\",\"@country\"],[[32,1,[\"device\",\"check\"]],[32,0,[\"tips\"]],[32,0,[\"title\"]],[32,1,[\"device\",\"tips\"]],\"USD\",\"US\"]],null],[2,\"\\n\"],[8,\"check/email-input\",[],[[\"@email\",\"@setEmail\"],[[32,0,[\"email\"]],[32,0,[\"setEmail\"]]]],null],[2,\"\\n\"],[8,\"check/proceed-button\",[],[[\"@pay\",\"@isSubmitDisabled\"],[[32,0,[\"pay\"]],[32,0,[\"isSubmitDisabled\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "qrpay-frontend/templates/device.hbs"
    }
  });

  _exports.default = _default;
});