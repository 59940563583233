define("qrpay-frontend/templates/components/check/items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l71g/Izw",
    "block": "{\"symbols\":[\"item\",\"@country\",\"@currency\",\"@tips\",\"@items\"],\"statements\":[[6,[37,2],[[30,[36,5],[[32,5],[32,4]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"table\"],[14,0,\"table table-borderless table-sm mt-2\"],[12],[2,\"\\n\\t\\t\"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[10,\"tr\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[10,\"td\"],[12],[1,[30,[36,1],[[32,1,[\"quantity\"]]],null]],[13],[2,\"\\n\\t\\t\\t\\t\\t\"],[10,\"td\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[32,1,[\"name\"]]],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[6,[37,2],[[32,1,[\"modifiers\"]]],null,[[\"default\"],[{\"statements\":[[10,\"br\"],[12],[13],[1,[32,1,[\"modifiers\"]]]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\\t\\t\"],[10,\"td\"],[14,0,\"text-right\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[30,[36,0],[[30,[36,2],[[32,1,[\"price\"]],[32,1,[\"price\"]],0],null],[32,3],[32,2]],null]],[2,\"\\n\\t\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[6,[37,2],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[10,\"tr\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[10,\"td\"],[12],[2,\"1\"],[13],[2,\"\\n\\t\\t\\t\\t\\t\"],[10,\"td\"],[12],[2,\"Charged tip\"],[13],[2,\"\\n\\t\\t\\t\\t\\t\"],[10,\"td\"],[14,0,\"text-right\"],[12],[1,[30,[36,0],[[32,4],[32,3],[32,2]],null]],[13],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"currency-format\",\"qty\",\"if\",\"-track-array\",\"each\",\"or\"]}",
    "meta": {
      "moduleName": "qrpay-frontend/templates/components/check/items.hbs"
    }
  });

  _exports.default = _default;
});