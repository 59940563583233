define("qrpay-frontend/templates/store-closed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hdVyahcM",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"vh-100 d-flex flex-column justify-content-center align-items-center\"],[12],[2,\"\\n\\t\"],[8,\"alert/cross\",[],[[],[]],null],[2,\"\\n\\t\"],[8,\"alert/title\",[],[[\"@title\"],[[32,1,[\"error\",\"title\"]]]],null],[2,\"\\n\\t\"],[8,\"alert/description\",[],[[\"@description\"],[[32,1,[\"error\",\"description\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "qrpay-frontend/templates/store-closed.hbs"
    }
  });

  _exports.default = _default;
});