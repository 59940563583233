define("qrpay-frontend/errors/throw-400-error", ["exports", "qrpay-frontend/errors/bad-payment", "qrpay-frontend/errors/bad-check", "qrpay-frontend/errors/pos-offline", "qrpay-frontend/errors/partial-disallowed", "qrpay-frontend/errors/store-closed", "qrpay-frontend/errors/loyalty-error", "qrpay-frontend/errors/member-not-active"], function (_exports, _badPayment, _badCheck, _posOffline, _partialDisallowed, _storeClosed, _loyaltyError, _memberNotActive) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LEGACY_ERRORS = {
    'Input validation error': _badPayment.default,
    'Tips are not allowed for this store and must be set to 0.': _badPayment.default,
    'Total amount from request does not equal total on check.': _partialDisallowed.default,
    'Store is not configured': _badCheck.default,
    'error with loyalty': _loyaltyError.default
  };

  var throw400Error = function throw400Error(responseJson) {
    if (responseJson.error.code) {
      switch (responseJson.error.code) {
        case _badPayment.default.BE_ERROR_CODE:
          throw new _badPayment.default();

        case _posOffline.default.BE_ERROR_CODE:
          throw new _posOffline.default();

        case _partialDisallowed.default.BE_ERROR_CODE:
          throw new _partialDisallowed.default();

        case _storeClosed.default.BE_ERROR_CODE:
          throw new _storeClosed.default();

        case _memberNotActive.default.BE_ERROR_CODE:
          throw new _memberNotActive.default(responseJson.error.message);

        case _badCheck.default.BE_ERROR_CODE:
        default:
          throw new _badCheck.default();
      }
    } // fallback to legacy errors until BE errors are implemented by documentation
    // @see https://confluence.shift4payments.com/display/QRPAY/QRPay+FE+-%3E+QRPay+BE


    throw new (LEGACY_ERRORS[responseJson.error.message] || _badCheck.default)();
  };

  var _default = throw400Error;
  _exports.default = _default;
});