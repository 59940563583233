define("qrpay-frontend/mirage/factories/device", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    code: 'ABCDEFG',
    check: function check() {
      return {
        totalAmount: 10000,
        taxAmount: 100,
        tipAmount: 100,
        invoiceNumber: 'invoice123'
      };
    },
    tips: function tips() {
      return {
        predefinedTipPercentage1: 1800,
        predefinedTipPercentage2: 2000,
        predefinedTipPercentage3: 2500,
        preselectedTip: 1,
        areAllowed: true
      };
    }
  });

  _exports.default = _default;
});