define("qrpay-frontend/templates/components/logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1KKJjhSQ",
    "block": "{\"symbols\":[\"@merchant\"],\"statements\":[[10,\"div\"],[14,0,\"m-2 p-1\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"logoUrl\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"img\"],[14,1,\"logo\"],[15,\"src\",[31,[[32,1,[\"logoUrl\"]]]]],[15,\"alt\",[31,[[32,1,[\"name\"]]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[14,1,\"logo-text\"],[14,0,\"d-flex flex-column justify-content-center align-items-center\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "qrpay-frontend/templates/components/logo.hbs"
    }
  });

  _exports.default = _default;
});