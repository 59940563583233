define("qrpay-frontend/templates/components/check/buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UKqSj3rJ",
    "block": "{\"symbols\":[\"@setPaymentMethod\",\"@buttonClass\",\"@pay\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"fixed-bottom \",[32,2]]]],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"toolbar d-flex flex-column justify-content-center align-items-center mb-4\"],[12],[2,\"\\n\\t\\t\"],[11,\"button\"],[24,0,\"btn pay-button apple-pay-button mb-2 pay-hidden\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,1],\"APPLE_PAY\"],null]],null],[12],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"showGooglePay\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[11,\"div\"],[24,0,\"google-pay\"],[4,[38,1],[\"click\",[30,[36,0],[[32,1],\"GOOGLE_PAY\"],null]],null],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"button\"],[14,0,\"btn pay-button google-pay-button pay-hidden\"],[14,4,\"button\"],[12],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\"],[11,\"button\"],[24,0,\"btn btn-primary btn-pay\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,3]],null]],null],[12],[2,\"\\n\\t\\t\\tPay with card\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"if\"]}",
    "meta": {
      "moduleName": "qrpay-frontend/templates/components/check/buttons.hbs"
    }
  });

  _exports.default = _default;
});