define("qrpay-frontend/mirage/routes/index", ["exports", "qrpay-frontend/mirage/routes/merchants", "qrpay-frontend/mirage/routes/devices", "qrpay-frontend/mirage/routes/v2/merchants"], function (_exports, _merchants, _devices, _merchants2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "merchants", {
    enumerable: true,
    get: function get() {
      return _merchants.default;
    }
  });
  Object.defineProperty(_exports, "devices", {
    enumerable: true,
    get: function get() {
      return _devices.default;
    }
  });
  Object.defineProperty(_exports, "merchantsv2", {
    enumerable: true,
    get: function get() {
      return _merchants2.default;
    }
  });
});