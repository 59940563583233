define("qrpay-frontend/helpers/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function add(numbers) {
    return numbers.reduce(function (a, b) {
      return Number(a) + Number(b);
    });
  });

  _exports.default = _default;
});