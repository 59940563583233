define("qrpay-frontend/templates/components/check/check-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vfXUzryy",
    "block": "{\"symbols\":[\"@check\"],\"statements\":[[2,\"#\"],[1,[32,1,[\"checkNumber\"]]],[6,[37,0],[[32,1,[\"color\"]]],null,[[\"default\"],[{\"statements\":[[10,\"span\"],[15,0,[31,[\"dot dot-\",[32,1,[\"color\"]]]]],[12],[13]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "qrpay-frontend/templates/components/check/check-number.hbs"
    }
  });

  _exports.default = _default;
});