define("qrpay-frontend/mirage/routes/devices", ["exports", "ember-cli-mirage/response"], function (_exports, _response) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /**
   * @param {Object} server - mirage server
   */
  function _default(server) {
    server.get('/devices/:code', function (_ref, request) {
      var devices = _ref.devices;
      var device = devices.all().models.filter(function (d) {
        return d.code === request.params.code;
      })[0];
      return device || new _response.default(404);
    });
    server.post('/devices/:code/authorize', function (_ref2) {
      var authorizes = _ref2.authorizes;
      return authorizes.all().models[0];
    });
    server.post('/devices/:code/payment', function () {
      return new _response.default(200);
    });
  }
});