define("qrpay-frontend/mirage/factories/check", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    id: 'uuid',
    checkNumber: '1234567',
    tableName: 'Table name',
    openedAt: '05/20/20 8:29 AM',
    employeeName: 'Employee Name',
    dualPricing: null,
    supplementalFee: null,
    total: 1000,
    subtotal: 800,
    taxes: 0,
    surcharges: 0,
    tips: 0,
    amountPaid: 0,
    amountDue: 1000,
    guestCount: 2,
    gratuity: 100,
    taxList: function taxList() {
      return [{
        name: "Tax 1",
        amount: 1
      }];
    },
    hiddenTaxList: function hiddenTaxList() {
      return [{
        name: "Tax 2",
        amount: 100
      }, {
        name: "Tax 3",
        amount: 100
      }];
    },
    otherTaxes: 0,
    items: function items() {
      return [{
        name: 'Item 1',
        quantity: 1 / 2,
        price: 1000,
        modifiers: 'Modifier 1, modifier2'
      }, {
        name: 'Item 2',
        quantity: 1 / 3,
        price: 1000,
        modifiers: 'Modifier 1, modifier2'
      }, {
        name: 'Charged Tip',
        quantity: 1
      }, {
        name: 'Item 4',
        quantity: 2.000,
        price: 4000,
        modifiers: 'Modifier 1, modifier2'
      }];
    }
  });

  _exports.default = _default;
});