define("qrpay-frontend/templates/merchant/table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/tqfiG2q",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[32,0,[\"isBackEnabled\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"mr-2 mt-2 mb-2 p-1 pl-0\"],[12],[2,\"\\n\\t\\t\"],[11,\"button\"],[24,1,\"btn-back\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"back\"]]],null]],null],[12],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"logo\",[],[[\"@merchant\",\"@back\"],[[32,0,[\"model\"]],[32,0,[\"back\"]]]],null],[2,\"\\n\\n\"],[10,\"h6\"],[14,0,\"text-center mt-3 pt-2\"],[12],[2,\"Enter \"],[1,[30,[36,3],[[32,0,[\"model\",\"labelPrompt\"]],\"Table\"],null]],[13],[2,\"\\n\\n\"],[11,\"form\"],[24,0,\"mt-4 mb-4 ml-2 m-2 p-1\"],[4,[38,1],[\"submit\",[32,0,[\"redirectToSTO\"]]],null],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\\t\"],[8,\"input\",[[24,0,\"form-control\"],[16,\"placeholder\",[31,[\"Enter \",[30,[36,3],[[32,0,[\"model\",\"labelPrompt\"]],\"Table\"],null]]]],[24,\"autofocus\",\"autofocus\"],[24,\"maxlength\",\"256\"]],[[\"@type\",\"@value\"],[\"text\",[32,0,[\"tableNotes\"]]]],null],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"button\"],[14,0,\"mt-3 btn btn-block btn-outline-primary\"],[15,\"disabled\",[32,0,[\"isSubmitDisabled\"]]],[14,4,\"submit\"],[12],[2,\"\\n\\t\\tContinue\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,5],[[30,[36,4],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"if\",\"or\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "qrpay-frontend/templates/merchant/table.hbs"
    }
  });

  _exports.default = _default;
});