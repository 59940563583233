define("qrpay-frontend/templates/components/alert/title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vNcnKxAm",
    "block": "{\"symbols\":[\"@class\",\"@title\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"display-4 mt-4 mx-2 text-center \",[32,1]]]],[12],[2,\"\\n\\t\"],[10,\"b\"],[12],[1,[32,2]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "qrpay-frontend/templates/components/alert/title.hbs"
    }
  });

  _exports.default = _default;
});