define("qrpay-frontend/templates/components/alert/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F+oCbxjS",
    "block": "{\"symbols\":[\"@route\",\"@routeParams\",\"@title\"],\"statements\":[[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"fixed-bottom\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"toolbar d-flex justify-content-center align-items-center mb-4\"],[12],[2,\"\\n\\t\\t\\t\"],[8,\"link-to\",[[24,0,\"btn btn-error\"]],[[\"@route\",\"@models\"],[[31,[[32,1]]],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\"],[1,[32,3]],[2,\"\\n\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "qrpay-frontend/templates/components/alert/button.hbs"
    }
  });

  _exports.default = _default;
});